import React from "react"
import Table from "../../components/Table";
import {SystemErrorsHeader, SystemTableName} from "../../constant/TableHeaders/system-error";
import {useErrorHandlersControl} from "../../service/error-handlers";
import CustomSelect from "../../components/Select";
import ErrorInfoPage from "./error-info-page";
import Loader from "../../components/Loader";
import SuccessOrErrorModal from "../../components/Modal/SuccessOrErrorModal";
import {INFO_MODAL} from "../../enum/successOrErrorModal";
import {Button} from "@mui/material";
import {useUpdateSystemErrorFromUnreadToRead} from "../../service/error-handlers/set-from-unread-to-read";


const SystemErrors = () => {
    const {data, sortBy, onAction, error} = useErrorHandlersControl()

    useUpdateSystemErrorFromUnreadToRead(data.clickedErrorRowInfo, data.setTableRowsData)

    return (
        <div className={"system-errors"}>
            {data.loading && <Loader/>}
            {data.detailsPageOpened && data.clickedErrorRowInfo && (
                <ErrorInfoPage
                    clickedErrorRowInfo={data.clickedErrorRowInfo}
                    onErrorDetailsClose={onAction.onErrorDetailsClose}
                    onUpdateStatus={onAction.onUpdateStatus}
                    updateStatusLoading={data.updateStatusLoading}
                />)}
            <CustomSelect
                style={{width: "50%"}}
                onChange={sortBy.onChange}
                value={sortBy.value}
                placeholder={sortBy.value}
                options={sortBy.sortOptions}
            />
            <Table
                header={SystemErrorsHeader}
                items={data.tableRowsData}
                tableName={SystemTableName}
                onClickHandler={onAction.onErrorDetailsOpen}
            />
            {error.openModal && (
                <SuccessOrErrorModal
                    open={error.openModal}
                    setOpen={error.setOpenModal}
                    type={INFO_MODAL.ERROR}
                    content={error.message}
                    button={<Button>OK</Button>}
                />
            )}
        </div>
    )
}


export default SystemErrors;