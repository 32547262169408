import React, { useEffect, useState } from "react";
import { Avatar, Button } from "@material-ui/core";
import {
  activateUser,
  changeStatusInEarnings,
  blockUser, changeFeature,
  blockUserHosting,
  activateUserHosting
} from "../../api/user";
import moment from "moment";
import StarsIcon from "@material-ui/icons/Stars";
import { UserRoleStyles } from "../../constant/user";
import Loader from "../../components/Loader";
import { useUsersPopScore } from "../../service/user/popscore";
import { useUsersSuccessRate } from "../../service/user/success-rate";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import { useMeetingAnalytics } from "../../service/analytics/meetingAnalytics";
import { RootStateOrAny, useSelector } from "react-redux";
import { IVariable } from "../../interfaces/variables";
import { useUserEarnings } from "../../service/user/user-earnings";
import { useUserSpending } from "../../service/user/user-spendings";
import { SERVICE_VARIABLE_NUMBERS } from "../../enum/variables";
import { EARNINGS_STATUS } from "../../enum/user";
import { useUserData } from "../../service/user/user-data";
import ChangeEarningsBalance from "./Modals/ChangeEarningsBalance";
import ChangeEarningsStatus from "./Modals/ChangeEarningsStatus";
import { useChangeUserRole } from "../../service/user/change-user-role";
import SuccessOrErrorModal from "../../components/Modal/SuccessOrErrorModal";
import { INFO_MODAL } from "../../enum/successOrErrorModal";
import { IExtendedUserInfo } from "../../interfaces/user";
import Modal from "../../components/Modal";

interface IProps {
  updateUserList: (
    user: IExtendedUserInfo,
    changedUser: IExtendedUserInfo
  ) => void;
  userId?: any;
  setOpen: any;
}

const User: React.FC<IProps> = ({ updateUserList, userId, setOpen }) => {
  const [imageLoaded, setImageLoaded] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [openCancelModal, setOpenCancelModal] = useState<boolean>(false);
  const [openChangeEarningsModal, setOpenChangeEarningsModal] = useState<boolean>(false);
  const [openVerificationIdModal, setOpenVerificationIdModal] = useState<boolean>(false)
  const variables = useSelector(
    (state: RootStateOrAny) => state.variable?.variables
  );
  const hourlyBaseFactor = useSelector(
    (state: RootStateOrAny) =>
      state?.variable?.variables?.find(
        (v: IVariable) => v.key === SERVICE_VARIABLE_NUMBERS.HBR
      )?.value
  );
  const {
    meetingAnalytics,
    meetingAnalyticsLoading,
    getCancelledMeetingWithinPeriodLabelsAndValue,
  } = useMeetingAnalytics(userId);
  const { totalScore } = useUsersPopScore(userId);
  const { successRate } = useUsersSuccessRate(userId);
  const { user, userLoading } = useUserData(userId, loading);
  const {
    onChangeUserRole,
    errorModal,
    successModal,
    loading: changeRoleLoading,
    showButton: showAdminRightsButton,
  } = useChangeUserRole(user, updateUserList);
  const { earnings, userEarningsLoading } = useUserEarnings(
    userId,
    userLoading
  );
  const { spending, userSpendingLoading } = useUserSpending(userId);

  const showLoader =
    userLoading ||
    meetingAnalyticsLoading ||
    userEarningsLoading ||
    userSpendingLoading ||
    changeRoleLoading;

  const hostingRateFactorValue = variables.filter(
    (v: IVariable) => v.key === user?.hrf
  )[0]?.value;
  const hostingRate = user?.hrf === SERVICE_VARIABLE_NUMBERS.CUSTOM ? user?.custom_hosting_rate : +hourlyBaseFactor * +hostingRateFactorValue;

  useEffect(() => {
    setLoading(changeRoleLoading);
  }, [changeRoleLoading]);

  const suspendUser = async () => {
    if (user) {
      setLoading(true);
      const response = await blockUser(+user?.id);
      response && setLoading(false);
    }
  };

  const unblockUser = async () => {
    if (user) {
      setLoading(true);
      const response = await activateUser(+user?.id);
      response && setLoading(false);
    }
  };

  const blockHosting = async () =>{
    if (user) {
      setLoading(true);
      const response = await blockUserHosting(+user?.id);
      response && setLoading(false);
    }
  }

  const unblockHosting = async () =>{
    if (user) {
      setLoading(true);
      const response = await activateUserHosting(+user?.id);
      response && setLoading(false);
    }
  }
 
  const changeEarningsStatus = async (status: string) => {
    setLoading(true);
    if (user) {
      const response = await changeStatusInEarnings(+user.id, status);
      response && setLoading(false);
    }
  };
  const onChangeFeature = async  () => {
    setLoading(true);
    if(user){
      const response = await changeFeature(+user.id);
      response && setLoading(false);
    }
  };

  const dob =
    moment(user?.dob).format("DD MMM YYYY") === "Invalid date"
      ? "Unknown"
      : moment(user?.dob).format("DD MMM YYYY");
  return (
    <div className="user-page details-container">
      {showLoader && <Loader />}
      <Button
        variant="outlined"
        className="go-back-button"
        onClick={() => setOpen(false)}
      >
        Go back
      </Button>
      <div className="containers">
        <div className="first-container">
          <h1 className="card-signature">User Info</h1>
          <div className="info-card">
            <div className="container-top">
              <div className="user-avatar">
                {user?.id_verified && imageLoaded && (
                  <div className={"avatar-badge"}>
                    <CheckCircleOutlineIcon />
                  </div>
                )}
                <Avatar
                  onLoad={() => setImageLoaded(true)}
                  src={user?.public_avatar_url}
                  style={{ width: "120px", height: "120px" }}
                />
                <span className="stars">
                  <strong>{totalScore}</strong>
                  <StarsIcon />
                </span>
                {user?.suspended ? (
                  <div className={"green-button block-button"} onClick={unblockUser}>
                    Unblock
                  </div>
                ) : (
                  <div className={"red-button block-button"} onClick={suspendUser}>
                    Block User
                  </div>
                )}
                {user?.hosting_disabled ? (
                  <div className={"green-button block-button"} onClick={unblockHosting}>
                    Unblock Hosting
                  </div>
                ) : (
                  <div className={"red-button block-button"} onClick={blockHosting}>
                    Mask Profile
                  </div>
                )}
              </div>
              <div className="user-info">
                <div className="user-top">
                  <div>
                    <h2
                      onClick={() =>
                        window.open(
                          `${process.env.REACT_APP_CHUSP_DOMAIN}/p/users/user/${user?.id}`,
                          "_blank"
                        )
                      }
                    >
                      {user?.first_name} {user?.last_name}
                    </h2>
                    <span
                      className={`${user && UserRoleStyles[user?.role]} role`}
                    >
                      {user?.id_verified && user?.role === 'HU' ? 'aHU' : (user?.role === 'VU'? 'FU':user?.role)}
                    </span>
                  </div>
                  <div>
                    {user?.id_verified && <span
                      className={"authenticated-label"}
                      onClick={() =>
                        setOpenVerificationIdModal(true)
                      }
                    >
                      Authenticated
                    </span>}
                  </div>
                </div>
                <p>
                  ID: <span>{!!user?.id ? user?.id : "Unknown"}</span>
                </p>
                <p>
                  VAT/GST Residence:{" "}
                  <span>
                    {!!user?.vat_residence ? user?.vat_residence : "Unknown"}
                  </span>
                </p>
                <p>
                  Email: <span>{!!user?.email ? user?.email : "Unknown"}</span>
                </p>
                <p>
                  Hosting rate:{" "}
                  <span>{Number.isNaN(hostingRate) ? 0 : hostingRate}$</span>
                </p>
                <p>
                  Success rate: <span>{successRate}%</span>
                </p>
                <p>
                  Email verified:{" "}
                  {user?.mail_verified ? (
                    <span className="green-button">Yes</span>
                  ) : (
                    <span className="red-button">No</span>
                  )}
                </p>
              </div>
            </div>
              <Button
                  onClick={onChangeFeature}
                  className={"featured"}
                  variant={"contained"}
                  color={"inherit"}
              >

                {user && !!user?.feature ? "Inactivate the featured profile" : "Activate the featured profile" }
              </Button>
            <br/>
            <hr />
            {showAdminRightsButton && (
              <Button
                onClick={onChangeUserRole}
                className={"give-admin-rights"}
                variant={"contained"}
              >
                Give admin rights
              </Button>
            )}
            <div className="container-bottom">
              <div>
                <h2>Account creation</h2>
                <span>
                  {moment(user?.account_creation).format("DD MMM YYYY")}
                </span>
              </div>
              <div>
                <h2>Last activity</h2>
                <span>
                  {moment(user?.last_active_at).format("DD MMM YYYY")}
                </span>
              </div>
              <div>
                <h2>DOB</h2>
                <span>{dob || "----"}</span>
              </div>
            </div>
          </div>
        </div>

        <div className="second-container">
          <h1 className="card-signature">Meeting Analytics</h1>
          <div className="info-card">
            <div className="main-data">
              <div>
                <h2>Number of Host User Meetings</h2>
                <span>{meetingAnalytics?.host_user_meetings_count}</span>
              </div>
              <hr />
              <div>
                <h2>Number of Fan User Meetings</h2>
                <span>{meetingAnalytics?.fan_user_meetings_count}</span>
              </div>
              <hr />
              <div>
                <h2>Number of Fan User Cancellation</h2>
                <span>
                  {meetingAnalytics?.fu_cancellation_analytics?.total}
                </span>
              </div>
              <hr />
              <div>
                <h2>Number of Host User Cancellation</h2>
                <span>
                  {meetingAnalytics?.hu_cancellation_analytics?.total}
                </span>
              </div>
            </div>
            <hr />
            <div className="bottom-data">
              <h2>Hosting cancellation</h2>
              <div className="container-bottom">
                {getCancelledMeetingWithinPeriodLabelsAndValue(
                  meetingAnalytics?.hu_cancellation_analytics
                    ?.cancelledWithinPeriods
                )?.map((c) => (
                  <div>
                    <h2>{c.label}</h2>
                    <span>{c.total}</span>
                  </div>
                ))}
              </div>
            </div>
            <div className="bottom-data">
              <h2>Fan cancellation</h2>
              <div className="container-bottom">
                {getCancelledMeetingWithinPeriodLabelsAndValue(
                  meetingAnalytics?.fu_cancellation_analytics
                    ?.cancelledWithinPeriods
                )?.map((c) => (
                  <div>
                    <h2>{c.label}</h2>
                    <span>{c.total}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className="third-container">
          <h1 className="card-signature">Earnings & Balance</h1>
          <div className="info-card">
            <div className="main-data">
              <div>
                <h2>Total spending on credits</h2>
                <span>
                  {!!spending?.spendingOnCredits
                    ? spending?.spendingOnCredits
                    : 0}
                  $
                </span>
              </div>
              <hr />
              <div>
                <h2>Total spending on meetings</h2>
                <span>
                  {!!spending?.spendingOnMeetings
                    ? spending?.spendingOnMeetings
                    : 0}
                  $
                </span>
              </div>
              <hr />
              <div>
                    <h2>Total spending on paid content</h2>
                    <span>
                  {!!spending?.spendingOnPaidContent
                      ? spending?.spendingOnPaidContent
                      : 0}
                        $
                </span>
                </div>
              <hr />
              <div>
                <h2>Monthly spending</h2>
                <span>
                  {!!spending?.spendingPerMonth
                    ? spending?.spendingPerMonth
                    : 0}
                  $
                </span>
              </div>
              <hr />
              <div>
                <h2>Earnings balance</h2>
                <span>
                  {!!earnings?.unremittedEarnings
                    ? earnings?.unremittedEarnings
                    : 0}
                  $
                </span>
              </div>
              <hr />
              <div>
                <h2>Remitted Earnings</h2>
                <span>
                  {!!earnings?.invoicedEarnings
                    ? earnings?.invoicedEarnings
                    : 0}
                  $
                </span>
              </div>
              <hr />
              <div>
                <h2>Lifetime earnings</h2>
                <span>
                  {!!earnings?.unremittedEarnings ||
                  !!earnings?.invoicedEarnings
                    ? earnings?.invoicedEarnings + earnings?.unremittedEarnings
                    : 0}
                  $
                </span>
              </div>
              <hr />
              <div>
                <h2>Monthly earnings</h2>
                <span>
                  {!!earnings?.earningsPerMonth
                    ? earnings?.earningsPerMonth
                    : 0}
                  $
                </span>
              </div>
              <hr />
              <div className="earnings-buttons">
                <div
                  className={"button violet-button"}
                  onClick={() => setOpenChangeEarningsModal(true)}
                >
                  Change Earnings
                </div>
                {user && user.earnings_status !== EARNINGS_STATUS.FREEZED && (
                  <div
                    className={"button blue-button"}
                    onClick={() =>
                      changeEarningsStatus(EARNINGS_STATUS.FREEZED)
                    }
                  >
                    Freeze earnings payout
                  </div>
                )}
                {user && user.earnings_status === EARNINGS_STATUS.FREEZED && (
                  <div
                    className={"button green-button"}
                    onClick={() =>
                      changeEarningsStatus(EARNINGS_STATUS.ALLOWED)
                    }
                  >
                    Unfreeze earnings payout
                  </div>
                )}
                <div
                  className={"button red-button"}
                  onClick={() => setOpenCancelModal(true)}
                >
                  Cancel earnings balance
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {openCancelModal && (
        <ChangeEarningsStatus
          open={openCancelModal}
          setOpen={setOpenCancelModal}
          userId={userId}
          setLoading={setLoading}
        />
      )}
      {openChangeEarningsModal && earnings && (
        <ChangeEarningsBalance
          open={openChangeEarningsModal}
          setOpen={setOpenChangeEarningsModal}
          earnings={earnings}
          userId={userId}
          setLoading={setLoading}
        />
      )}
      {successModal.open && (
        <SuccessOrErrorModal
          title={"Success"}
          open={successModal.open}
          setOpen={successModal.setOpen}
          type={INFO_MODAL.SUCCESS}
          content={"This user was successfully given admin rights"}
        />
      )}
      {errorModal.open && (
        <SuccessOrErrorModal
          title={"Error"}
          open={errorModal.open}
          setOpen={errorModal.setOpen}
          type={INFO_MODAL.ERROR}
          content={"Something went wrong..."}
        />
      )}
      {openVerificationIdModal && 
        <Modal
          open={openVerificationIdModal}
          setOpen={setOpenVerificationIdModal}
          title={"Verification ID"}
          content={<div className="verification-id-container">
            <img src={`${process.env.REACT_APP_FULL_PUBLIC_S3_BUCKET_URL}/${user?.id_path}`} alt="picture_is_not_loaded"/>
          </div>}
          confirmButton={"OK"}
        />
      }
    </div>
  );
};

export default User;
