import React, {useState} from "react";
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import CompareArrowsIcon from '@material-ui/icons/CompareArrows';
import {
    COMPLAINT_DECISION,
    COMPLAINT_USER_ROLE,
    COMPLAINTS_SEND_FROM, COMPLAINTS_SEND_FROM_SORT_PARAMS,
    RETRIEVE_INFO_FROM_EVENT_OBJECT
} from "../../enum/complaint"
import {Button} from "@material-ui/core";
import {IComplaint, IEventWhichIncludesComplaints} from "../../interfaces/complaints";
import {retrievePropertyValueFromTheObject} from "../../utils/retrive-value-from-object";
import {USER_ROLE} from "../../enum/user";
import UserInfo from "./components/user-info";
import ComplaintInfo from "./components/complaint-info";
import ComplaintDecisions from "./components/decision-modal";
import {complaintButtonsActions} from "../../api/complaint";
import Loader from "../../components/Loader";
import SuccessOrErrorModal from "../../components/Modal/SuccessOrErrorModal";
import {INFO_MODAL} from "../../enum/successOrErrorModal";

interface IProps {
    updateComplaintDataArr: (eventId: string, decision: string) => void,
    eventWithComplaints: IEventWhichIncludesComplaints,
    setOpen: any
}

const ComplaintDetails: React.FC<IProps> = ({updateComplaintDataArr, eventWithComplaints, setOpen}) => {
    const [openDecisionModal, setOpenDecisionModal] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)
    const [openErorModal, setOpenErrorModal] = useState<boolean>(false)

    const sortedComplaintsBySendFromParam = eventWithComplaints?.complaints?.sort((a, b) =>
        COMPLAINTS_SEND_FROM_SORT_PARAMS[a.send_complaint_from as keyof typeof COMPLAINTS_SEND_FROM_SORT_PARAMS] -
        COMPLAINTS_SEND_FROM_SORT_PARAMS[b.send_complaint_from as keyof typeof COMPLAINTS_SEND_FROM_SORT_PARAMS]
    )

    const preEscalationRoutineComplaints = eventWithComplaints?.complaints?.filter((complaint) =>
        complaint.send_complaint_from !== COMPLAINTS_SEND_FROM.COMPLAINT_FORM)

    const complaintsLaunchedThroughComplaintForm = eventWithComplaints?.complaints?.filter((complaint) =>
        complaint.send_complaint_from === COMPLAINTS_SEND_FROM.COMPLAINT_FORM)

    const complaintWithoutReply = complaintsLaunchedThroughComplaintForm?.length === 1 || preEscalationRoutineComplaints?.length === 1


    const respondent = eventWithComplaints?.complaints[0]?.complaint_author?.id === eventWithComplaints?.initiator_user?.id ?
        eventWithComplaints?.hosting_user :
        eventWithComplaints?.initiator_user

    const userRoleInTheMeeting = (userId: string) => {
        return eventWithComplaints?.hosting_user?.id === userId ? USER_ROLE.HU : USER_ROLE.FU
    }

    const renderUserRoleTitle = (complaint: IComplaint) => {
        switch (complaint.send_complaint_from) {
            case COMPLAINTS_SEND_FROM.PRE_MEETING_EXIT:
                return "Reporter";
            case COMPLAINTS_SEND_FROM.COMPLAINT_RESPOND:
                return "Respondent";
            case COMPLAINTS_SEND_FROM.COMPLAINT_FORM:
                return "Complaint author";
            default:
                return ""
        }
    }

    const renderNoReplyMessage = () => {
        if (!!preEscalationRoutineComplaints?.length) {
            return "User hasn't responded yet"
        } else {
            return "User hasn't sent a complaint targeted to this meeting"
        }
    }

    const onMakeDecision = async (adminDecision: string) => {
        let complaintId: string
        if (complaintWithoutReply) {
            complaintId = eventWithComplaints?.complaints[0]?.id
        } else {
            const complaint = adminDecision === COMPLAINT_DECISION.ACCEPTED_HU ?
                eventWithComplaints?.complaints?.find((complaint) => complaint?.complaint_author?.id === eventWithComplaints?.hosting_user?.id) :
                eventWithComplaints?.complaints?.find((complaint) => complaint?.complaint_author?.id === eventWithComplaints?.initiator_user?.id)
            complaintId = complaint ? complaint?.id : ""
        }
        setLoading(true)
        const response = await complaintButtonsActions(complaintId, {decision: adminDecision})
        if (response && response?.data) {
            updateComplaintDataArr(eventWithComplaints?.id, adminDecision)
            setOpen(false)
        }
        if (response && response?.message) {
            setOpenErrorModal(true)
        }
        if (response) {
            setLoading(false)
        }
    }

    return (
        <div className="complaint-details-page  details-container">
            {loading && <Loader/>}
            <Button variant="outlined" className="go-back-button" onClick={() => setOpen(false)}>Go back</Button>
            <div className="info-wrapper">
                <div className="complaints">
                    {sortedComplaintsBySendFromParam?.map((complaint, index) => (
                        <React.Fragment>
                            <div className="user-info">
                                <h2>{renderUserRoleTitle(complaint)}</h2>
                                <UserInfo
                                    user={complaint?.complaint_author}
                                    roleInTheMeeting={userRoleInTheMeeting(complaint?.complaint_author?.id)}
                                    complaintUserRole={complaint?.send_complaint_from === COMPLAINTS_SEND_FROM.PRE_MEETING_EXIT ?
                                        COMPLAINT_USER_ROLE.AUTHOR
                                        : COMPLAINT_USER_ROLE.RESPONDENT
                                    }
                                />
                                <ComplaintInfo complaint={complaint}/>
                            </div>
                            {!complaintWithoutReply && index === 0 && <CompareArrowsIcon/>}
                        </React.Fragment>
                    ))}
                    {complaintWithoutReply && (
                        <React.Fragment>
                            <ArrowRightAltIcon/>
                            <div className="user-info">
                                <h2>Respondent</h2>
                                <UserInfo
                                    user={respondent}
                                    roleInTheMeeting={userRoleInTheMeeting(respondent?.id)}
                                    complaintUserRole={COMPLAINT_USER_ROLE.RESPONDENT}
                                />
                                <div className="no-complaints">
                                    <p>{renderNoReplyMessage()}</p>
                                </div>
                            </div>
                        </React.Fragment>
                    )}
                </div>
                <div className="complaint-event-info">
                    <h2>Meeting details</h2>
                    {RETRIEVE_INFO_FROM_EVENT_OBJECT?.map((info) => (
                        <div className={"info"}>
                            <span>{info.label}</span>
                            <span>{retrievePropertyValueFromTheObject(
                                info.key,
                                eventWithComplaints,
                                info.valueTypeToExpect,
                                info.subKey)}
                            </span>
                        </div>
                    ))}
                </div>
            </div>
            <div className="complaint-page-buttons">
                <span><div onClick={() => setOpenDecisionModal(true)} className={"green-button"}>Admin decision</div></span>
                {complaintWithoutReply && <span><div onClick={async () => onMakeDecision(COMPLAINT_DECISION.REJECTED)}
                                                     className={"red-button"}>Reject</div></span>}
                <span><div onClick={async () => onMakeDecision(COMPLAINT_DECISION.FURTHER_INVESTIGATION)}
                           className={"blue-button"}>Further investigation</div></span>
            </div>
            {openDecisionModal && (
                <ComplaintDecisions
                    open={openDecisionModal}
                    setOpen={setOpenDecisionModal}
                    onMakeDecision={onMakeDecision}
                    complaintsWithNoReply={complaintWithoutReply}
                    hostingUser={eventWithComplaints?.hosting_user}
                    fanUser={eventWithComplaints?.initiator_user}
                    singleComplaint={eventWithComplaints?.complaints[0]}
                />
            )}
            {openErorModal && (
                <SuccessOrErrorModal
                    open={openErorModal}
                    setOpen={setOpenErrorModal}
                    type={INFO_MODAL.ERROR}
                    content={"Something went wrong..."}
                    onComplete={() => setOpen(false)}
                    button={<button>Ok</button>}
                />
            )}
        </div>
    );
};

export default ComplaintDetails;
