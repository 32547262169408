import React from "react";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import Input from "../../components/Input";
import Table from "../../components/Table";
import {ComplaintsHeader, ComplaintsTableName} from "../../constant/TableHeaders/complaints-header";
import Loader from "../../components/Loader";
import {useControlComplaints} from "../../service/complaints/complaints";
import ComplaintDetails from "../ComplaintDetails";
import {COMPLAINT_TYPE_TO_RENDER, COMPLAINTS_SEND_FROM} from "../../enum/complaint";

interface IProps {
    complaintType: typeof COMPLAINT_TYPE_TO_RENDER.LAUNCHED_THROUGH_COMPLAINT_FORM | typeof COMPLAINT_TYPE_TO_RENDER.HOSTING_ESCALATION_ROUTINE
}

const Complaints: React.FC<IProps> = ({complaintType}) => {
    const complaintsToRequest = complaintType === COMPLAINT_TYPE_TO_RENDER.LAUNCHED_THROUGH_COMPLAINT_FORM ?
        [COMPLAINTS_SEND_FROM.COMPLAINT_FORM] :
        [COMPLAINTS_SEND_FROM.PRE_MEETING_EXIT, COMPLAINTS_SEND_FROM.COMPLAINT_RESPOND]

    const {
        loading,
        search,
        complaints
    } = useControlComplaints(complaintsToRequest)

    return (
        <>
            {loading && !search.value && <Loader/>}
            {complaints.complaintInfo.open && <ComplaintDetails
                updateComplaintDataArr={complaints.update}
                eventWithComplaints={complaints.complaintInfo.data}
                setOpen={complaints.complaintInfo.setOpen}
            />}
            <div className="complaints-page">
                <div className="top-panels">
                    <label className="input-wrapper">
                        <Input
                            onChange={search.onChange}
                            value={search.value}
                            placeholder={"Search"}
                            type={"search"}
                            classInput="input-search"
                        />
                        <span className="clear-input" onClick={search.clear}>{search ?
                            <HighlightOffIcon/> : null}</span>
                    </label>
                </div>
                {complaints?.items?.length ? (
                    <Table
                        header={ComplaintsHeader}
                        items={complaints.items}
                        tableName={ComplaintsTableName}
                        onClickHandler={complaints.openComplaintCard}
                    />
                ) : <h2 className="nth-found">Nothing found</h2>}
            </div>
        </>
    );
};

export default Complaints;
