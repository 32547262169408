import {Button} from "@material-ui/core";
import React from "react"
import TicketInfo from "./TicketInfo";
import {TICKET_TYPE} from "../../enum/tickets";

interface IProps {
    type: typeof TICKET_TYPE.INDIVIDUAL_USER_REPORT | typeof TICKET_TYPE.INDIVIDUAL_POST_REPORT
        | typeof TICKET_TYPE.FEEDBACK_FORM | typeof TICKET_TYPE.SUPPORT_FORM
    info: any,
    setOpen: any,
    updateTicketsDataList: (ticket: any, resolvedTicket: any) => void
}

const TicketDetails: React.FC<IProps> = ({type, info, setOpen, updateTicketsDataList}) => {

    return (
        <div className="ticket-container details-container">
            <Button variant="outlined" className="go-back-button" onClick={() => setOpen(false)}>Go back</Button>
            <TicketInfo
                type={type}
                info={info}
                setOpen={setOpen}
                updateTicketsDataList={updateTicketsDataList}
            />
        </div>
    )
}

export default TicketDetails;
