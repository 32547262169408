import React, {CSSProperties, useEffect, useRef, useState} from 'react'
import EmailEditor from 'react-email-editor'
import {getDocs, updateDocs} from "../../api/policy";
import {List, ListItem, ListItemText, ListSubheader, Typography} from "@material-ui/core";
import SuccessOrErrorModal from "../../components/Modal/SuccessOrErrorModal";
import {INFO_MODAL} from "../../enum/successOrErrorModal";
import {DOCUMENTS_REQUEST} from "../../enum/documents";
import CustomSelect from "../../components/Select";
import {useCopyToClipboard} from "react-use";
import Loader from "../../components/Loader";
import {Accordion, AccordionDetails, AccordionSummary, Alert} from "@mui/material";
import {DOCUMENT_CREATE_HTML, DOCUMENT_CREATE_LINK, DOCUMENT_DESCRIPTION} from "../../enum/document-instruction";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

interface IProps {
    type: typeof DOCUMENTS_REQUEST.POLICY.value | typeof DOCUMENTS_REQUEST.TERMS.value
}

const STYLES_SAVE_BUTTON: CSSProperties = {position: "fixed", bottom: 0, width: "100%", left: 0, fontWeight: "bold"}

const Document: React.FC<IProps> = ({type}) => {
    // eslint-disable-next-line
    const [docsData, setDocsData] = useState<any>()
    const [openSuccessModal, setOpenSuccessModal] = useState<boolean>(false)
    const [openErrorModal, setOpenErrorModal] = useState<boolean>(false)
    const [pageReference, setPageReference] = useState<string>("")
    const [uniqueIdName, setUniqueIdName] = useState<string>("")
    const [sectionTitle, setSectionTitle] = useState<string>("")
    const [loading, setLoading] = useState<boolean>(false)
    const [state, copyToClipboard] = useCopyToClipboard();
    const toolsConfig = {
        image: {
            enabled: false
        },
        divider: {
            enabled: false
        },
        button: {
            enabled: false
        },
        menu: {
            enabled: false
        }
    };

    const emailEditorRef = useRef<any>(null);
    const parsedData = () => {
        try {
            return JSON.parse(docsData?.data)
        } catch (e) {
            console.log(e)
        }
    }

    const exportHtml = () => {
        if (emailEditorRef !== null) {
            emailEditorRef?.current?.editor?.exportHtml((html: any) => {
                setLoading(true)
                updateDocs({type, data: JSON.stringify(html)}).then((response) => {
                        response && setLoading(false)
                        response &&
                        response?.data?.isExpected === true ?
                            setOpenSuccessModal(true) :
                            setOpenErrorModal(true)
                    }
                )
            });
        }
    };
    const onLoad = () => {
        emailEditorRef.current?.editor?.loadDesign(parsedData()?.design);
    };

    useEffect(() => {
        window.scroll(0, 0)
    }, [type])

    useEffect(() => {
        (async () => {
            setLoading(true)
            const response = await getDocs(type)
            response && setLoading(false)
            if (response && response.data) {
                setDocsData(response.data)
            }
        })()
    }, [type])

    useEffect(() => {
        onLoad()
        // eslint-disable-next-line
    }, [docsData])

    const selectOptions = Object.values(DOCUMENTS_REQUEST).map((v) => ({label: v.label, value: v.value.toLowerCase()}))
    const finalLink = type === pageReference.toUpperCase() ? `#${uniqueIdName}` : `${process.env.REACT_APP_CHUSP_DOMAIN}/p/${pageReference}?${uniqueIdName}`
    const sectionHtmlGeneratedResult = `<div id="${uniqueIdName}">${sectionTitle}</div>`

    const renderAccordionDescription = () => {
        return (
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography>Description</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    {DOCUMENT_DESCRIPTION.map((desc) => (
                        <List aria-label="contacts">
                            <ListItem style={{width: '100%'}}>
                                <ListItemText primary={desc.text}/>
                            </ListItem>
                        </List>
                    ))}
                </AccordionDetails>
            </Accordion>
        )
    }

    const instructionLink = "https://s3.ap-southeast-1.amazonaws.com/dev-admin.chusp.com/Terms_Policies_HTML_Hyperlinks.docx"

    return (
        <>
            <div style={{display: "flex", alignItems: "flex-start"}}>
                <h5 style={{marginRight: 10}}>{DOCUMENTS_REQUEST[type].label}</h5>
                <a style={{fontSize: "12px"}} href={instructionLink}>More detailed instruction</a>
            </div>

            {renderAccordionDescription()}
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography>Create a hyperlink within the document </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <List subheader={<ListSubheader>Step Sequence :</ListSubheader>} aria-label="contacts">
                        <ListItem style={{width: '100%'}}>
                            <ListItemText
                                primary={DOCUMENT_CREATE_LINK.FIRST_STEP}/>
                        </ListItem>
                        <CustomSelect
                            style={{paddingTop: 0, paddingLeft: 15}}
                            placeholder={"Select a document"}
                            options={selectOptions}
                            onChange={(event: any) => setPageReference(event.value)}
                        />
                        <ListItem style={{width: '100%'}}>
                            <ListItemText primary={DOCUMENT_CREATE_LINK.SECOND_STEP}/>
                        </ListItem>
                        <input
                            style={{marginLeft: 15, border: "1px solid lightgrey", paddingLeft: 15}}
                            value={uniqueIdName}
                            onChange={(e) => setUniqueIdName(e.target.value)}
                            placeholder={"Type it here"}
                        />
                        <ListItem>
                            <ListItemText primary={DOCUMENT_CREATE_LINK.THIRD_STEP}/>
                        </ListItem>
                        <ListItem onClick={() => copyToClipboard(finalLink)} button style={{width: '100%'}}>
                            <ListItemText primary={DOCUMENT_CREATE_LINK.FOURTH_STEP + ` ${finalLink}`}/>
                        </ListItem>
                        {state.value?.includes(finalLink) && <Alert severity="success">Copied to Clipboard!</Alert>}
                        <ListItem style={{width: '100%'}}>
                            <ListItemText primary={DOCUMENT_CREATE_LINK.FIFTH_STEP}/>
                        </ListItem>
                        <ListItem style={{width: '100%'}}>
                            <ListItemText primary={DOCUMENT_CREATE_LINK.SIX_STEP}/>
                        </ListItem>
                        <ListItem style={{width: '100%'}}>
                            <ListItemText primary={DOCUMENT_CREATE_LINK.SEVENTH_STEP}/>
                        </ListItem>
                        <ListItem style={{width: '100%'}}>
                            <ListItemText primary={DOCUMENT_CREATE_LINK.EIGHT_STEP}/>
                        </ListItem>
                        <ListItem style={{width: '100%'}}>
                            <ListItemText primary={"-\tClick ‘Save’ within the domain"}/>
                        </ListItem>
                        <ListItem style={{width: '100%'}}>
                            <ListItemText primary={"- Save the document within the Admin panel"}/>
                        </ListItem>
                    </List>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography>Connect the logic to the content </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <List subheader={<ListSubheader>- It will find an HTML container which will find/identify ‘Annex A’
                        - Need to tell UI where ‘Annex A’ within the Terms is to be found
                    </ListSubheader>} aria-label="contacts">
                        <ListItem style={{width: '100%'}}>
                            <ListItemText primary={DOCUMENT_CREATE_HTML.FIRST_STEP}/>
                        </ListItem>
                        <ListItem style={{width: '100%'}}>
                            <ListItemText primary={DOCUMENT_CREATE_HTML.SECOND_STEP}/>
                        </ListItem>
                        <ListItem>
                            <ListItemText primary={DOCUMENT_CREATE_HTML.THIRD_STEP}/>
                        </ListItem>
                        <ListItem style={{width: '100%'}}>
                            {"<div id="}
                            <input
                                placeholder={"appendix_4"}
                                value={uniqueIdName}
                                onChange={(e) => setUniqueIdName(e.target.value)}
                            />
                            {">"}
                            <textarea
                                style={{
                                    height: sectionTitle.length > 700 ? 300 : 100,
                                    width: '80%',
                                    padding: "5px 15px"
                                }}
                                wrap={'soft'}
                                placeholder={"Enter/copy-paste"}
                                value={sectionTitle}
                                onChange={(e) => setSectionTitle(e.target.value)}
                            />
                            {"</div>"}
                        </ListItem>
                        <ListItem button onClick={() => copyToClipboard(sectionHtmlGeneratedResult)}>
                            <ListItemText
                                primary={DOCUMENT_CREATE_HTML.SIXTH_STEP + ` ${sectionHtmlGeneratedResult}`}/>
                        </ListItem>
                        {state.value?.includes(sectionHtmlGeneratedResult) &&
                        <Alert severity="success">Copied to Clipboard!</Alert>}
                        <ListItem style={{width: '100%'}}>
                            <ListItemText primary={DOCUMENT_CREATE_HTML.SEVENTH_STEP}/>
                        </ListItem>
                    </List>
                </AccordionDetails>
            </Accordion>
            {loading && <Loader/>}
            <EmailEditor
                ref={emailEditorRef}
                onLoad={onLoad}
                tools={toolsConfig}
                style={{minHeight: "80vh"}}
            />
            <button style={STYLES_SAVE_BUTTON} type="button" onClick={exportHtml} className="btn btn-success">Save
            </button>
            {openSuccessModal && <SuccessOrErrorModal
                open={openSuccessModal}
                setOpen={setOpenSuccessModal}
                type={INFO_MODAL.SUCCESS}
                content={`${DOCUMENTS_REQUEST[type].label} were successfully saved`}
            />}
            {openErrorModal && <SuccessOrErrorModal
                open={openErrorModal}
                setOpen={setOpenErrorModal}
                type={INFO_MODAL.ERROR}
                content={"Something went wrong..."}
            />}
        </>
    )
}
export default Document;
