import React, {useCallback, useEffect, useState} from 'react';
import Input from "../../../components/Input";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import CustomSelect from "../../../components/Select";
import {SORT_BY} from "../../../constant/sort-complaints";
import Table from "../../../components/Table";
import {
    SupportsHeader,
    SupportsTableName
} from "../../../constant/TableHeaders/supports-header";
import {useAllTickets} from "../../../service/tickets/tickets";
import {ISort} from "../../../interfaces/sort";
import Loader from "../../../components/Loader";
import {bodyVariablesForTickets} from '../../../utils/body-variables';
import {INIT_PAGINATION, PAGE_SIZE} from '../../../constant/pagination';
import {TICKET_STATUS, TICKET_TYPE} from '../../../enum/tickets';
import {usePagination} from '../../../service/common/pagination';
import {getAllTickets} from '../../../api/tickets';
import TicketDetails from '../tickets-details';
import {onHandleSearchAndSort} from '../../../utils/sort-search';
import {overflowBody} from '../../../utils/style-document';
import {useUpdateTicketFromUnreadToRead} from "../../../service/tickets/update-from-unread-to-read";


const Supports: React.FC = () => {
    const [search, setSearch] = useState<string>("");
    const [sort, setSort] = useState<ISort>({value: "DESC", label: "DESC"})
    const [currentPage, setCurrentPage] = useState<number>(0)
    const [supportTickets, setSupportTickets] = useState<any>()
    const [openSupportInfo, setOpenSupportInfo] = useState<boolean>(false)
    const [supportInfo, setSupportInfo] = useState<number>()

    useUpdateTicketFromUnreadToRead(supportInfo, setSupportTickets)

    const {onHandleSort, onHandleSearch, onHandleClear} = onHandleSearchAndSort(setCurrentPage, setSort, setSearch)

    const {tickets, loading} = useAllTickets(
        bodyVariablesForTickets(INIT_PAGINATION, sort?.value, '', {
            key: search,
            ticket_status: 'ACTIVE',
            ticket_type: TICKET_TYPE.SUPPORT_FORM
        })
    )

    const [newData] = usePagination(getAllTickets, {
        variables: bodyVariablesForTickets(
            {page: currentPage + 1, pageSize: PAGE_SIZE},
            sort?.value,
            '',
            {key: search, ticket_status: 'ACTIVE', ticket_type: TICKET_TYPE.SUPPORT_FORM},
        )
    });

    useEffect(() => {
        if (newData?.items && newData?.items?.length > 0) {
            setSupportTickets([...supportTickets, ...newData?.items])
            setCurrentPage(newData?.currentPage)
        }
        // eslint-disable-next-line
    }, [newData])

    useEffect(() => {
        if (tickets) {
            setSupportTickets(tickets?.items)
        }
    }, [tickets])

    const updateSupportList = useCallback((ticket: any, resolvedTicket: any) => {
        const index = supportTickets?.findIndex((i: any) => i?.id === ticket?.id)
        setSupportTickets(((prevState: any) => {
            prevState[index] = resolvedTicket
            return prevState
        }))
        // eslint-disable-next-line
    }, [setSupportTickets])


    const openSupportCard = (value: any) => {
        setOpenSupportInfo(true)
        setSupportInfo(value)
    }

    overflowBody(openSupportInfo)

    const showOnlyActiveSupports = supportTickets?.filter((ticket: any) => ticket?.status !== TICKET_STATUS.RESOLVED)

    return (
        <>
            {loading && !search && <Loader/>}
            {openSupportInfo && <TicketDetails
                type={TICKET_TYPE.SUPPORT_FORM}
                info={supportInfo}
                setOpen={setOpenSupportInfo}
                updateTicketsDataList={updateSupportList}
            />}
            <div className='reports-page'>
                <div className="top-panels">
                    <label className="input-wrapper">
                        <Input
                            onChange={onHandleSearch}
                            value={search}
                            placeholder={"Search"}
                            type={"search"}
                            classInput="input-search"
                        />
                        <span className="clear-input">
            <span className="clear-input" onClick={onHandleClear}>
            {search ? <HighlightOffIcon/> : null}
          </span>
          </span>
                    </label>
                    <CustomSelect
                        onChange={onHandleSort}
                        placeholder={sort.label || "Sort"}
                        options={SORT_BY}
                    />
                </div>
                {!!showOnlyActiveSupports?.length ? (
                        <div className="supports-table">
                            <Table
                                header={SupportsHeader}
                                items={showOnlyActiveSupports}
                                tableName={SupportsTableName}
                                onClickHandler={openSupportCard}
                            />
                        </div>
                    ) :
                    <h2 className="nth-found">Nothing found</h2>
                }
            </div>
        </>
    )
}

export default Supports;
