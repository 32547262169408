import React, { useState, useEffect } from "react";
import "./index.scss";
import SystemMeetingLink from "../../components/SystemMeetingLink";
import Button from "../../components/Button";
import Table from "../../components/Table";
import { SystemMeetingsHeader, SystemMeetingTableName } from "../../constant/TableHeaders/system-meeting-header";
import { basicBodyVariables } from "../../utils/body-variables";
import { useGetSystemMeetings } from "../../service/system-meeting/system-meeting";
import { INIT_PAGINATION, PAGE_SIZE } from "../../constant/pagination";
import { ISort } from "../../interfaces/sort";
import { SORT_BY } from "../../constant/sort-meetings";
import moment from "moment";
import { SYSTEM_MEETING } from "../../enum/system-meeting";
import { usePagination } from "../../service/common/pagination";
import { getSystemMeetingData, updateSystemMeetingData } from "../../api/system-meetings";
import Modal from "../../components/Modal";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Checkbox } from "@material-ui/core";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonCheckedOutlined"
import RadioButtonCheckedOutlined from "@material-ui/icons/RadioButtonCheckedOutlined";
import SuccessOrErrorModal from "../../components/Modal/SuccessOrErrorModal";
import { INFO_MODAL } from "../../enum/successOrErrorModal";

interface ISystemMeeting {
    comment: string | null,
    created_at: string,
    id: string,
    status: string,
    system_meeting_id: string,
    updated_at: string | null
}

const SystemMeetings: React.FC = () => {

    // const [search, setSearch] = useState<string>("");
    // const [sort, setSort] = useState<ISort>({value: SORT_BY[0].value, label: SORT_BY[0].label});
    const search: string = "";
    const sort: ISort = { value: SORT_BY[0].value, label: SORT_BY[0].label };
    const [systemMeetings, setSystemMeetings] = useState<any>([])
    const [generatedLink, setGeneratedLink] = useState<string | null>(null)
    const [currentPage, setCurrentPage] = useState<number>(0)

    const [openModal, setOpenModal] = useState<boolean>(false)
    const [openSuccessOrErrorModal, setOpenSuccessOrErrorModal] = useState<boolean>(false)
    const [isCommentUpdated, setIsCommentUpdated] = useState<boolean>(false)

    const [selectedItem, setSelectedItem] = useState<ISystemMeeting | null>(null)
    const [adminComment, setAdminComment] = useState<string | null>(null)
    const [meetingFailed, setMeetingFailed] = useState<boolean>(false)

    const { systemMeetingsData } = useGetSystemMeetings(
        basicBodyVariables(INIT_PAGINATION, search, sort.value)
    )

    useEffect(() => {
        setSystemMeetings(systemMeetingsData?.items)
    }, [systemMeetingsData])

    const [newData] = usePagination(getSystemMeetingData, {
        variables: basicBodyVariables({ page: currentPage + 1, pageSize: PAGE_SIZE }, search, sort.value)
    });


    useEffect(() => {
        if (systemMeetings && newData?.items && newData?.items?.length > 0) {
            setSystemMeetings(systemMeetings.concat(newData.items))
            setCurrentPage(newData?.currentPage)
        }
        // eslint-disable-next-line
    }, [newData])

    const generateMeetingLink = () => {
        let meeting_id = btoa(moment().unix().toString()).slice(0, -2)
        let meeting_link = process.env.REACT_APP_CHUSP_DOMAIN + SYSTEM_MEETING.PATH + meeting_id;
        setGeneratedLink(meeting_link)
    }

    const handleAdminComment = (item: any) => {
        setSelectedItem(item)
        setOpenModal(true)
    }

    const closeModal = () => {
        setOpenModal(false)
        setAdminComment(null)
        setSelectedItem(null)
        setMeetingFailed(false)
    }

    useEffect(() => {
        selectedItem?.comment && setAdminComment(selectedItem.comment);
        selectedItem?.status === "Other_Failure" && setMeetingFailed(true);
    }, [selectedItem])

    const handleChangeComment = (e: any) => {
        const { value } = e.target;
        setAdminComment(value)
    }

    const modalContent = () => {
        return <div>
            <TextField
                id="outlined-multiline-static"
                multiline
                minRows={4}
                maxRows={10}
                fullWidth={true}
                value={!!adminComment ? adminComment : ''}
                onChange={handleChangeComment}
            />
            <FormControlLabel
                control={
                    <Checkbox
                        checked={!!meetingFailed}
                        icon={<RadioButtonUncheckedIcon />}
                        checkedIcon={<RadioButtonCheckedOutlined />}
                        onChange={() => setMeetingFailed(!meetingFailed)}
                    />
                }
                label="Meeting Failed"
                labelPlacement="end"
            />
        </div>
    }

    const saveCommentToDB = async () => {
        const data = {
            system_meeting_id: selectedItem?.id ? selectedItem.id : '',
            admin_comment: adminComment ? adminComment : '',
            meeting_failed: meetingFailed
        }
        const res = await updateSystemMeetingData(data);
        if (res.success === true) {
            setIsCommentUpdated(true)
            setOpenSuccessOrErrorModal(true)
        } else {
            setIsCommentUpdated(false)
            setOpenSuccessOrErrorModal(true)
        }
    }

    return (
        <div className="system-meetings-page" >
            <div className="top-panels">
                <div className="top-panel-left">
                    <SystemMeetingLink
                        onClickHandler={() => generatedLink && navigator.clipboard.writeText(generatedLink)}
                        meetingLink={generatedLink ? generatedLink : SYSTEM_MEETING.GENERATE}
                        showCopyLink={!!generatedLink}
                    />
                </div>
                <div className="top-panel-right">
                    <div className="generate-meeting-link-button">
                        <Button name="Generate Meeting Link" onClickHandler={generateMeetingLink} />
                    </div>
                </div>
            </div>
            <div className="system-meeting-table-container">
                <Table items={systemMeetings} header={SystemMeetingsHeader} tableName={SystemMeetingTableName} onButtonClickHandler={handleAdminComment} />
            </div>

            <Modal
                open={openModal}
                setOpen={closeModal}
                title={`System Meeting ID ${selectedItem?.id}`}
                subtitle={`Admin Comment`}
                confirmButton={"Submit"}
                content={modalContent()}
                onComplete={saveCommentToDB}
                disabled={
                    (selectedItem?.status === "Other_Failure"
                    ? true
                    : !meetingFailed)
                    && (selectedItem?.comment === adminComment)
                   }
            />
            <SuccessOrErrorModal
                onComplete={isCommentUpdated ? () => window.location.reload() : () => setOpenSuccessOrErrorModal(false)}
                open={openSuccessOrErrorModal}
                setOpen={setOpenSuccessOrErrorModal}
                content={isCommentUpdated ? "Admin comment saved successfully!" : "Server Error!"}
                type={isCommentUpdated ? INFO_MODAL.SUCCESS : INFO_MODAL.ERROR}
                button={"Close"}
                buttonClass={"close-modal-button"}
            />
        </div>
    );
};

export default SystemMeetings;
