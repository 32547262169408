import React from "react";
import { TableProps } from "../../interfaces/table";
import { ColumnFactory } from "./column-factory";

const Table: React.FC<TableProps> = ({ items, header, tableName, onClickHandler, onButtonClickHandler, onLinkClickHandler}) => {

  const renderTable = () => {
    const tableHead = header?.map((headerItem, index) => (
      <th style={{padding: headerItem.meta.key === "is_read" ? 0 : ""}} key={index}>{headerItem.title}</th>
    ));

    const tableData = items.length>0 && items?.map((item, index: number) => (
      <tr key={index} className={onClickHandler && "tr-pointer"} onClick={() => onClickHandler && onClickHandler(item)}>
        {header?.map((col, index: number) => (
          <td style={{padding: col.meta.key === "is_read" ? 0 : ""}} key={index}>
            <ColumnFactory columnType={col.type} meta={col.meta} item={item} onButtonClickHandler={onButtonClickHandler} onLinkClickHandler={onLinkClickHandler}/>
          </td>
        ))}
      </tr>
    ));

    return (
      <table className="table mb-0">
        <thead>
          <tr>{tableHead}</tr>
        </thead>
        <tbody>{tableData}</tbody>
      </table>
    );
  }

  return (
    <div className="row">
      <div className="col">
        <div className="card card-small mb-4">
          <div className="card-header border-bottom">
            <h6 className="m-0">{tableName}</h6>
          </div>
          <div className="card-body p-0 pb-3 text-center">{renderTable()}</div>
        </div>
      </div>
    </div>
  );
};

export default Table;
