import React from "react";
import moment from 'moment-timezone';
import {ColumnProps} from "../../../interfaces/table";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import {getDateStringFromUTCAndTImezone} from "../../../utils/date-string-utc-timezone";
import {GET_FULL_DATE_WITH_NO_TIME} from "../../../enum/date-formats";

const TextColumn: React.FC<ColumnProps> = ({meta, item}) => {
    let style;
    let icon: any;
    const currentTimezone = moment.tz.guess();
    const toTimeZone = (time_start: string) => {
        const dateConvertedToTimezone = getDateStringFromUTCAndTImezone(time_start, currentTimezone)
        return moment(dateConvertedToTimezone).format(GET_FULL_DATE_WITH_NO_TIME)
    }

    if (item[meta.key] === "HU") {
        style = "host-user";
    } else if (item[meta.key] === "ADU") {
        style = "admin-user";
    } else if (item[meta.key] === "VU") {
        style = "visitor-user";
    } else if (meta.key === "email") {
        style = "email";
    } else if (meta.key === "created_at") {
        item[meta.key] = toTimeZone(item[meta.key])
    } else if (meta.key === "complaint_assets") {
        icon = item[meta.key].length ? <AttachFileIcon/> : "----";
    }else if (meta.key === "is_read") {
        // IF IS NOT READ - SHOW RED DOT INDICATOR
        icon = <FiberManualRecordIcon style={{color: !item[meta.key] ? "red" : "transparent", fontSize: 12, marginLeft: 10, marginTop: 20}}/>;
    }

    const renderRow = () => {
        if (Array.isArray(meta.key)) {
            return meta.key.map((el: string, index: number) => (
                <span key={index}> {item[el]} </span>
            ));
        } else if (meta.object_key && typeof item[meta.key] === "object") {
            return Array.isArray(meta.object_key) ? (
                meta.object_key.map((el: string) => <span> {item[meta.key][el]} </span>)
            ) : (
                <span>{item[meta.key][meta.object_key]}</span>
            );
        } else if (icon) {
            return <span>{icon}</span>;
        } else {
            if(meta.key === "role" && item[meta.key] === "VU"){
                return <span>{"FU" || "-----"}</span>;
            }else{
                return <span>{item[meta.key] || "-----"}</span>;
            }
        }
    };

    return <span className={style}>{renderRow()}</span>;
};

export default TextColumn;
