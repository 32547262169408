import React, {useRef} from "react"
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    IconButton,
    TextField,
    Typography,
} from "@mui/material";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import {useFAQControl} from "../../service/faq";
import Loader from "../../components/Loader";
import EditAnswerQuestionForm from "./components/edit-answer-question";
import SuccessOrErrorModal from "../../components/Modal/SuccessOrErrorModal";
import {INFO_MODAL} from "../../enum/successOrErrorModal";
import ReactQuill from "react-quill";
import {FORMATS, MODULES} from "../../constant/react-quill";


const FAQ = () => {
    const faqFormRef = useRef<HTMLDivElement>(null)
    const faqContainerRef = useRef<HTMLDivElement>(null)
    const addQuestionFormRef = useRef<HTMLDivElement>(null)
    const {topics, loading, mods, errorModal} = useFAQControl()

    return (
        <div ref={faqContainerRef} className={"faq-container"}>
            {loading && <Loader/>}
            <h2>Topics</h2>
            <div ref={faqFormRef} className={"faq-form"}>
                <Button className={"add-new-topic"} onClick={() => {
                    mods.createTopic.isActive ?
                        mods.createTopic.deactivate() :
                        mods.createTopic.activate()
                }}>
                    {mods.createTopic.isActive ? "Close" : <span>Add new topic <AddIcon/></span>}
                </Button>
                {mods.createTopic.isActive && (
                    <div className={"create-mode"}>
                        {topics.createInputFields?.map((input, index) => (
                            <>
                                {input?.key !== "answer" ? (
                                    <TextField
                                        key={index}
                                        style={{margin: "10px 0", background: "white"}}
                                        fullWidth
                                        label={input.label}
                                        placeholder={input.placeholder}
                                        onChange={(e) => topics.topic.onValuesChange(input.key, e.target.value)}
                                        value={topics.topic.values[input.key as keyof typeof topics.topic.values]}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        multiline={input?.multiline}
                                        minRows={input?.rows}
                                        maxRows={10000}
                                    />
                                ) : (
                                    <ReactQuill
                                        className={"answer-textfield"}
                                        placeholder={"Enter answer"}
                                        theme="snow"
                                        formats={FORMATS}
                                        modules={MODULES}
                                        value={topics.topic.values[input.key as keyof typeof topics.topic.values]}
                                        onChange={(content) => topics.topic.onValuesChange(input.key, content)}
                                    />
                                )}
                            </>
                        ))}
                        <Button
                            disabled={topics.topic.disableAdd}
                            className={"save-btn"}
                            onClick={topics.topic.create}
                            fullWidth>
                            Save
                        </Button>
                    </div>
                )}
            </div>
            {!!topics?.list?.length && topics?.list?.map((topic) => (
                <div className={"faq"}>
                    <div className={"faq-header"}>
                        {mods.editTopic.isActive.id === topic?.id ?
                            <div>
                                <input
                                    value={topics.topic.values["topic"]}
                                    onChange={(e) => topics.topic.onValuesChange("topic", e.target.value)}
                                />
                                <Button disabled={topics.topic.disableEdit}
                                        onClick={async () => await topics.topic.edit(topic?.id)}
                                        className={"save-topic-btn"}
                                >
                                    Save
                                </Button>
                                <Button onClick={mods.editTopic.deactivate} className={"save-topic-btn"}>
                                    Cancel
                                </Button>
                            </div>

                            :
                            <h3 onClick={() => mods.editTopic.activate(topic?.id)}>{topic.topic}</h3>
                        }
                        <div className={"action-btns"}>
                            <Button onClick={() => mods.addAnswerQuestion.activate(topic?.id)}>
                                Add Q/A
                            </Button>
                            {mods.editTopic.isActive.id !== topic?.id &&
                            <Button onClick={() => mods.editTopic.activate(topic?.id)}>
                                Edit topic header
                            </Button>}
                            <Button onClick={async () => await topics.topic.delete(topic?.id)}>
                                Delete topic
                            </Button>
                        </div>
                    </div>
                    {mods.addAnswerQuestion.isActive?.id === topic?.id && <EditAnswerQuestionForm
                        containerRef={addQuestionFormRef}
                        buttonTitle={"Add"}
                        onButtonAction={async () => topics.answerQuestion.create(topic?.id)}
                        onNewFaqValueChange={topics.topic.onValuesChange}
                        newFaqValues={topics.topic.values}
                        disableAction={topics.answerQuestion.disableSaveOrEdit}
                    />}
                    {topic?.value?.map((v) => (
                        <>
                            {mods.editAnswerQuestion.isActive.question === v?.question ?
                                <div className={"accordion"}>
                                    <EditAnswerQuestionForm
                                        containerRef={addQuestionFormRef}
                                        buttonTitle={"Save"}
                                        onButtonAction={async () => await topics.answerQuestion.edit()}
                                        onNewFaqValueChange={topics.topic.onValuesChange}
                                        newFaqValues={topics.topic.values}
                                        disableAction={topics.answerQuestion.disableSaveOrEdit}
                                    />
                                    <div className={"question-edit-btns"}>
                                        <IconButton onClick={() => mods.editAnswerQuestion.deactivate()}>
                                            <CloseIcon/>
                                        </IconButton>
                                        <IconButton onClick={() => topics.answerQuestion.delete(v?.question)}>
                                            <DeleteIcon/>
                                        </IconButton>
                                    </div>
                                </div>
                                : (
                                    <div className={"accordion"}>
                                        <Accordion style={{width: "100%"}}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon/>}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                            >
                                                <Typography className={"question"}>{v?.question}</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails className={"accordion-details"}>
                                                <Typography className={"answer"}>
                                                    <div className={"quillwrapper"} dangerouslySetInnerHTML={{__html: v?.answer}}/>
                                                </Typography>
                                            </AccordionDetails>
                                        </Accordion>
                                        <div className={"question-edit-btns"}>
                                            <IconButton onClick={() => mods.editAnswerQuestion.activate(v?.question)}>
                                                <EditIcon/>
                                            </IconButton>
                                            <IconButton onClick={() => topics.answerQuestion.delete(v?.question)}>
                                                <DeleteIcon/>
                                            </IconButton>
                                        </div>
                                    </div>
                                )
                            }
                        </>))}
                </div>))}
            {errorModal.open && <SuccessOrErrorModal
                open={errorModal.open}
                setOpen={errorModal.setOpen}
                type={INFO_MODAL.ERROR}
                content={errorModal.message}
                button={<button>Ok</button>}
                onComplete={errorModal.onActionClicked}
            />}
        </div>)
}


export default FAQ;