import {COLUMN_TYPE} from "../../interfaces/table";
import {ROUTES} from "../../enum/routes";
import {HeaderItemType} from "../../interfaces/table";

export const UsersTableName = "Users";
export const UsersOnclick = ({ history, item }: any) => {
  history.push({
    pathname: `${ROUTES.PAGES.USER}${item.id}`,
    state: item.id
  });
}

export const UsersHeader = [
  {
    title: "ID",
    type: COLUMN_TYPE.STRING,
    meta: {
      key: "id",
    },
  },
  {
    title: "Email",
    type: COLUMN_TYPE.STRING,
    meta: {
      key: "email",
    },
  },
  {
    title: "User name",
    type: COLUMN_TYPE.STRING,
    meta: {
      key: ["first_name", "last_name"],
    },
  },
  {
    title: "Residence",
    type: COLUMN_TYPE.STRING,
    meta: {
      key: "residence"
    }
  },
  {
    title: "Date joined",
    type: COLUMN_TYPE.STRING,
    meta: {
      key: "account_creation",
    },
  },
  {
    title: "Last activity",
    type: COLUMN_TYPE.STRING,
    meta: {
      key: "last_active_at",
    },
  },
  {
    title: "Role",
    type: COLUMN_TYPE.STRING,
    meta: {
      key: "role",
    },
  },
  {
    title: "Edit",
    type: COLUMN_TYPE.BUTTON,
    meta: {
      label: "Edit",
      onClick: ({ history, item }: any) => { 
        history.push({
        pathname: `${ROUTES.PAGES.EDIT_USER}`,
        state: {id: item?.id}
      });
    }
    },
  },

] as HeaderItemType[];
