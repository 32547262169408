import React, {useState} from 'react';
import {DataGrid, GridCellParams} from '@mui/x-data-grid';
import {useSystemVariablesTableData} from "../../service/variables/system-variables-table";
import EditCellModals from "./Modals/EditCellModal";


const SystemVariables = () => {
    const {columns, rows} = useSystemVariablesTableData()
    const [clickedRow, setClickedRow] = useState<GridCellParams | null>(null)

    return (
        <div className={'data-grid-container'}>
            <DataGrid
                sortModel={[{field: "key", sort: "asc"}]}
                rows={rows}
                columns={columns}
                onCellClick={(params) => setClickedRow(params)}
                hideFooter={true}

            />
            {clickedRow && <EditCellModals clickedRow={clickedRow}/>}
        </div>
    )
}
export default SystemVariables;
